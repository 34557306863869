// https://github.com/jaydenseric/apollo-upload-client/issues/88
// How to use:
//   mutate({
//     variables: {
//       file
//     },
//     context: {
//       fetchOptions: {
//         onProgress: (ev: ProgressEvent) => {
//           setProgress(ev.loaded / ev.total);
//         },
//         onAbortPossible: (abortHandler: any) => {
//           abort = abortHandler;
//         }
//       }
//     }
//   })

const parseHeaders = (rawHeaders: string): Headers => {
  const headers = new Headers()
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')

  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const parts = line.split(':')
    const key = parts.shift()?.trim()

    if (key) {
      const value = parts.join(':').trim()

      headers.append(key, value)
    }
  })

  return headers
}

type OnloadOptions = {
  headers: Headers
  status: number
  statusText: string
} & Record<string, unknown>

type CustomFetchOptions = {
  onAbortPossible?: (abortHandler: unknown) => void
  onProgress?: (ev: ProgressEvent) => void
} & RequestInit

export const uploadFetch = (
  url: RequestInfo | URL,
  options: CustomFetchOptions
): Promise<Response> =>
  new Promise((resolve, reject) => {
    const xhr: XMLHttpRequest = new XMLHttpRequest()

    xhr.onload = () => {
      const opts: OnloadOptions = {
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
        status: xhr.status,
        statusText: xhr.statusText,
      }

      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL')
      const body =
        'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText

      resolve(new Response(body, opts))
    }
    xhr.onerror = () => {
      reject(new Error('Network request failed'))
    }
    xhr.ontimeout = () => {
      reject(new Error('Network request failed'))
    }
    xhr.open(options.method || '', url as string, true)

    Object.keys(options.headers as Headers).forEach((key) => {
      const headerValue = options.headers
        ? (options.headers[key as keyof HeadersInit] as string)
        : ''

      xhr.setRequestHeader(key, headerValue)
    })

    if (xhr.upload && options.onProgress) {
      xhr.upload.onprogress = options.onProgress
    }

    options.onAbortPossible?.(() => xhr.abort())

    xhr.send(
      options.body as Document | XMLHttpRequestBodyInit | null | undefined
    )
  })

export const customFetchWithOnProgress = (
  uri: RequestInfo | URL,
  options?: CustomFetchOptions
): Promise<Response> => {
  if (options?.onProgress) {
    return uploadFetch(uri, options)
  }

  return fetch(uri, options)
}
