import { ApolloLink } from '@apollo/client'
import { OperationDefinitionNode } from 'graphql/language/ast'
import { Kind } from 'graphql/language/kinds'

const uri = (
  URI: string,
  {
    conditionKey,
    replaceToUri,
  }: {
    conditionKey?: string
    replaceToUri?: string
  } = {}
) =>
  new ApolloLink((operation, forward) => {
    const context = operation.getContext()
    let uri = context.uri || URI

    if (conditionKey && context[conditionKey] && replaceToUri) {
      uri = uri.replace(URI, replaceToUri)
      operation.setContext({
        uri,
      })
    }

    const definition = operation.query.definitions.find(
      (it) => it.kind === Kind.OPERATION_DEFINITION
    ) as OperationDefinitionNode

    const operationType = definition?.operation || ''

    operation.setContext({
      uri: `${uri}?${operationType}:${operation.operationName}`,
    })

    return forward(operation)
  })

export default uri
