import { onError } from '@apollo/client/link/error'

import Toast from 'Toast'

const errorHTML = (message: string) => `
<div style="width: 250px">
  <span>
      ${window.T(
        'ERROR: Something went wrong, please contact SameSystem administrator!'
      )}
    </span>
    <pre 
      style="
        white-space: pre-line;
        font-size: 10px;
        font-family: monospace;
        font-weight: 400;
      "
    >${message}</pre>
</div>`

const error = () => {
  return onError((response) => {
    const { graphQLErrors, networkError } = response

    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach((error) => {
        if (error.message === 'Not authenticated!') {
          window.location.href = '/logout'
        }
      })

      graphQLErrors
        .filter((error) =>
          ['system_error', 'validation_error'].includes(error.type)
        )
        .forEach((error) => {
          if (error.message) {
            if (import.meta.env.PROD && error.type === 'system_error') {
              Toast.error(errorHTML(error.message), {
                textAsHtml: true,
                ...error.toastOptions,
              })
            } else {
              Toast.error(error.message, error.toastOptions || {})
            }
          }
        })
    }

    if (networkError) {
      if (Array.isArray(networkError)) {
        networkError.forEach((singleError) => {
          if (singleError.message) Toast.error(singleError.message)
        })
      } else if (networkError.message) {
        if ('response' in networkError) {
          // networkError is ServerError or ServerParseError
          Toast.error(
            `${networkError.response.statusText} (${networkError.statusCode})`
          )
        } else {
          // networkError is Error
          Toast.error(`${networkError.message}`)
        }
      }
    }

    // Retry the request, returning the new observable
    // return response.forward(response.operation)
  })
}

export default error
