import { ApolloLink, HttpLink, Operation } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { Kind } from 'graphql/language/kinds'

import cable from 'utils/cable'

import { customFetchWithOnProgress } from './customFetchWithOnProgress'

const hasSubscriptionOperation = ({ query }: Operation) => {
  const definition = getMainDefinition(query)

  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === 'subscription'
  )
}

const link = (uri: string, useFileUpload: boolean) => {
  const options = {
    credentials: 'same-origin',
    uri,
  }

  if (useFileUpload)
    return createUploadLink({
      ...options,
      fetch: customFetchWithOnProgress,
    })

  return new HttpLink(options)
}

const httpLink = (uri: string, useFileUpload: boolean) =>
  ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    link(uri, useFileUpload)
  )

export default httpLink
