import { AvailableLocale } from 'types/window'

import systemLocalesCSV from '../../../db_global/tables/system_locales.csv'

export type SystemLocale = {
  active: string
  code: string
  id: string
  mobile: string
  name: string
  title: string
}

const systemLocales = systemLocalesCSV.map((systemLocale) => {
  const keys = Object.keys(systemLocale)[0].split(';') as Array<
    keyof SystemLocale
  >
  const values = (Object.values(systemLocale)[0] as string).split(';')

  const mappedObject: Record<string, string> = {}

  keys.forEach((key, index) => {
    mappedObject[key] = values[index]
  })

  mappedObject.name = mappedObject.title

  return mappedObject as SystemLocale
})

export const setWindowLocaleName = () => {
  window.localeName =
    (systemLocales
      .map((systemLocale) =>
        systemLocale.code.replace(
          /_(.*$)/,
          (_match, p1) => '-' + p1.toUpperCase()
        )
      )
      .find(
        (systemLocaleCode) =>
          systemLocaleCode ===
          window.locale_name.replace(
            /_(.*$)/,
            (_match, p1) => '-' + p1.toUpperCase()
          )
      ) as AvailableLocale) || 'en'
}

export default systemLocales
